import './_functions';

//////////// S L I D E R S ///////////////////////////////////////

import Splide from '@splidejs/splide';
import { AutoScroll } from '@splidejs/splide-extension-auto-scroll';

//--------------------------------------------------------------//

var check_systems_slider = document.querySelector(".systems-slider");
if (check_systems_slider) {
  // console.log(`systems-slider is present`);

  var systems_slider = new Splide('.systems-slider', {
    type: 'slide',
    perPage: 4,
    focus: 0,
    arrows: false,
    pagination: true,
    gap: 20,
    breakpoints: {
      1199: {
        perPage: 3,
        width: 967,
      },
      1016: {
        perPage: 2,
        width: 638,
      },
      687: {
        fixedWidth: 309,
        fixedHeight: 263,
      },
      480: {
        fixedWidth: 266,
        fixedHeight: 175,
      },
    },
  }).mount();

} else {
  // console.log(`systems-slider is NOT present`);
}

//--------------------------------------------------------------//

var check_cases_slider = document.querySelector(".cases-slider");
if (check_cases_slider) {
  // console.log(`cases-slider is present`);

  var cases_slider = new Splide('.cases-slider', {
    type: 'loop',
    perPage: 6,
    focus: 0,
    arrows: false,
    pagination: true,
    gap: 20,
    breakpoints: {
      1199: {
        perPage: 5,
      },
      1023: {
        perPage: 4,
      },
      767: {
        perPage: 3,
      },
      480: {
        destroy: true,
      },
    },
  }).mount();

} else {
  // console.log(`cases-slider is NOT present`);
}

//--------------------------------------------------------------//

var check_marquee = document.querySelector(".marquee");
if (check_marquee) {
  // console.log(`marquee is present`);

  var marquee = new Splide('.marquee', {
    type: 'loop',
    drag: 'free',
    focus: 'center',
    autoWidth: true,
    arrows: false,
    pagination: false,
    gap: 30,
    autoScroll: {
      speed: 1,
    },
  }).mount({ AutoScroll });

} else {
  // console.log(`marquee is NOT present`);
}

//--------------------------------------------------------------//

var check_marquee_2 = document.querySelector(".marquee-2");
if (check_marquee_2) {
  // console.log(`marquee-2 is present`);

  var marquee_2 = new Splide('.marquee-2', {
    type: 'loop',
    drag: 'free',
    focus: 'center',
    autoWidth: true,
    arrows: false,
    pagination: false,
    gap: 30,
    direction: "rtl",
    autoScroll: {
      speed: 1,
    },
  }).mount({ AutoScroll });

} else {
  // console.log(`marquee-2 is NOT present`);
}

//--------------------------------------------------------------//

var check_case_slider = document.getElementById("case-slider");
if (check_case_slider) {
  // console.log(`case-slider is present`);

  var case_slider = new Splide('#case-slider', {
    type: "loop",
    pagination: false,
    classes: {
      arrows: 'splide__arrows slider-arrows',
      prev: 'splide__arrow--prev btn-slr prev',
      next: 'splide__arrow--next btn-slr next',
    },
  });

  var thumbnails = document.getElementsByClassName('thumbnail');
  var current;

  for (var i = 0; i < thumbnails.length; i++) {
    initThumbnail(thumbnails[i], i);
  }

  function initThumbnail(thumbnail, index) {
    thumbnail.addEventListener('click', function() {
      case_slider.go(index);
    });
  }

  case_slider.on('mounted move', function() {
    var thumbnail = thumbnails[case_slider.index];

    if (thumbnail) {
      if (current) {
        current.classList.remove('is-active');
      }

      thumbnail.classList.add('is-active');
      current = thumbnail;
    }
  });

  case_slider.mount();

} else {
  // console.log(`case-slider is NOT present`);
}

///////// Валидация формы ////////////////////////////////////////$RECYCLE.BIN

const checkbox = document.querySelector('.checkbox')
const btn_submit = document.querySelector('.btn--submit')

if (checkbox) {
  function handleBtnSubmitClicked(e) {
    e.preventDefault()
  }

  checkbox.addEventListener('change', (event) => {
    if (!event.currentTarget.checked) {
      btn_submit.classList.add('btn--inactive')
      btn_submit.addEventListener('click', handleBtnSubmitClicked, false)
        // console.log("кнопка не работает")
    } else {
      btn_submit.classList.remove('btn--inactive')
      btn_submit.removeEventListener('click', handleBtnSubmitClicked, false)
        // console.log("кнопка работает")
    }
  })
}
